<template>
    <div>
      <Breadcrumbs main="" title="Monitor" />
      <div class="container-fluid">
        <div class="row"> 
          <div class="col-md-12 mt-2">
                  <b-row class="">
                  <b-col class="col-md-4 col-12">
                    <b-input-group>
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="col-md-6"
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>

                  <b-col class="col-md-2 col-12">
                    <b-form-select name="type" size="sm" v-model="selectedUser" :options="users_opt" @change="getMonitors()" required></b-form-select>
                  </b-col>
  
                  <b-col class="text-right col-md-6 col-12">
                    <b-form-group
                      label-cols="10"
                      label="Per page"
                      class="mb-0"
                    >
                      <b-form-select
                        class="col-md-10"
                        v-model="perPage"
                        :options="pageOptions"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="col-md-12" v-if="loading">
                    <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
                <div v-else class="table-responsive datatable-vue text-left">
                  <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :fields="tablefields"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @filtered="onFiltered"
                  >
                  <template #table-caption>Monitor List.</template>
  
                  <template #cell(name)="data">
                    <div v-if="data.item.type == 5">
                    <b-button v-b-toggle=data.item._id class="btn" size="sm" variant="primary">{{ data.item.name }}<i class="ml-2 icofont icofont-arrow-down"></i></b-button>
                    <b-collapse :id="data.item._id" role="tabpanel">
                    <b-card-body>
                      <b-card-text><code>{{heartbeat_url+ data.item._id}}</code></b-card-text>
                      <b-card-text class="link_txt"  @click="CopyHearbeatUrl(heartbeat_url+ data.item._id)">Copy</b-card-text>
                    </b-card-body>
                  </b-collapse>
                    </div>
                    <div v-else>
                      <b class="">{{ data.item.name }}</b>
                    </div>
                  </template>
                  
                  <template #cell(interval)="data">
                    <b class="">{{ data.item.interval }} - minute</b>
                  </template>
                  <template #cell(createdAt)="data">
                    <b class="">{{ new Date(data.item.createdAt).toLocaleDateString() }} - {{new Date(data.item.createdAt).toTimeString().substring(0, new Date(data.item.createdAt).toTimeString().indexOf("GMT"))}}</b>
                  </template>
  
                  <template #cell(status)="data">
                            <span v-if="data.item.status" class="text-success">Active</span>
                            <span v-else class="text-danger">InActive</span>
                   </template>
                  </b-table>
                </div>
  
                <b-col md="6" class="mt-3 p-0">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    class="my-0"
                  ></b-pagination>
                </b-col>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import ManagerController from "../../../services/managerController";
  import UserController from "../../../services/userController";
  import Config from "../../../services/config";
  export default {
      name: "Monitor",
    data() {
      return {
          open_import_monitor:false,
          heartbeat_url :'',
          hearbeat_modal:false,
          tablefields: [
          { key: "name", label: "Name", sortable: true },
          // { key: "type", label: "type", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "url", label: "URL", sortable: true },
          { key: "interval", label: "Interval", sortable: true },
          // { key: "timeout", label: "timeout", sortable: true },
          // { key: "ssl_error", label: "ssl_error", sortable: true },
          // { key: "ssl_expiry_reminder", label: "ssl_expiry_reminder", sortable: true },
          // { key: "auth_type", label: "auth_type", sortable: true },
          // { key: "auth_username", label: "auth_username", sortable: true },
          // { key: "auth_password", label: "auth_password", sortable: true },
          // { key: "http_method", label: "http_method", sortable: true },
          // { key: "keyword", label: "keyword", sortable: true },
          // { key: "case_sensitive", label: "case_sensitive", sortable: true },
          // { key: "alertWhen", label: "alertWhen", sortable: true },
          // { key: "port", label: "port", sortable: true },
          // { key: "useremail", label: "useremail", sortable: true },
           { key: "createdAt", label: "Created At", sortable: true },
          // { key: "updatedAt", label: "updatedAt", sortable: true },
          // { key: "action", label: "Action", sortable: true },
        ],
        items:[],
        filter: null,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 50],
        monitor_data:{},
        loading: false,
        users:{},
        selectedUser:null,
        users_opt:[{ value: null, text:'All Monitors' }]
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.tablefields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
    },
    mounted() {
      this.getMonitors();
      this.getUsers();
      this.heartbeat_url = Config.hertbeatUrl
    },
    methods: {
      async getMonitors() {
        this.loading = true;
        this.items = []
        let response
        if(this.selectedUser != null){
           response = await ManagerController.filterMonitors('userid='+this.selectedUser);
        }
        else{
          response = await ManagerController.getMonitors();
        }
        if (response.result) {
          this.items = response.data;
          this.totalRows = this.items.length;
        }
        this.loading = false;
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      CopyHearbeatUrl(str){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = str;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
      },
      async getUsers(){
            let response = await UserController.getAllUsers()
            if (response.result) {
                this.users = response.message.filter(t=>{
                  if(t.role != 'manager'){
                        return true;
                    }
                });
                this.users.map((p)=> {
                  this.users_opt.push({'value':p._id,'text':p.username+' ('+p.email+')'})
                })
            }
            else{
              this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
        },
    },
  };
  </script>
  
  
  
  